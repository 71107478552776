import { z } from 'zod';

import { parseDate } from '../../../_utils';

const optional = z.string().optional();
const errorMessageOptions = z.string().min(1, { message: 'FORMS.OFFER.ERRORS.OPTIONS' });
const errorMessageRequired = z.string().min(1, { message: 'FORMS.OFFER.ERRORS.REQUIRED' });
const errorMessageEmail = z.string().email({ message: 'FORMS.OFFER.ERRORS.EMAIL' });
const errorMessagePhone = z
  .string()
  .min(1, { message: 'FORMS.OFFER.ERRORS.REQUIRED' })
  .regex(new RegExp(/^[\d.+/-]*$/), 'FORMS.OFFER.ERRORS.PHONE');

//STEP 1

const stepOneAudienceSchema = z.object({
  audience: z.literal('parent'),
});

const stepOneCaregiverSchema = z.object({
  audience: z.literal('caregiver'),
  caregiverDepartment: errorMessageRequired,
  caregiverEmail: errorMessageEmail,
  caregiverFirstName: errorMessageRequired,
  caregiverLastName: errorMessageRequired,
  caregiverPhone: errorMessagePhone,
  isDiscussed: errorMessageOptions,
});

export const offerFormStepOneSchema = z.discriminatedUnion('audience', [stepOneAudienceSchema, stepOneCaregiverSchema], {
  required_error: 'FORMS.OFFER.ERRORS.REQUIRED',
});

// STEP 2

export const offerFormStepTwoBaseSchema = z.object({
  childBirthdate: z.preprocess(
    parseDate,
    z.instanceof(Date, { message: 'FORMS.OFFER.ERRORS.REQUIRED' }).refine(date => {
      return date <= new Date(Date.now());
    }, 'FORMS.OFFER.ERRORS.DATE'),
  ),
  childFirstName: errorMessageRequired,
  childLastName: errorMessageRequired,
  gender: errorMessageOptions,
});

const offerFormStepTwoSchoolSchema = z.object({
  grade: errorMessageRequired,
  school: errorMessageRequired,
});

export const offerFormStepTwoSchema = offerFormStepTwoBaseSchema.merge(offerFormStepTwoSchoolSchema);

//STEP 3

export const stepThreeFatherAddressFields = z.object({
  fatherBus: optional,
  fatherNumber: errorMessageRequired,
  fatherStreet: errorMessageRequired,
  fatherZip: errorMessageRequired,
});

export const stepThreeBaseFatherSchema = z.object({
  family: errorMessageRequired,
  fatherEmail: errorMessageEmail,
  fatherFirstName: errorMessageRequired,
  fatherLanguage: errorMessageRequired,
  fatherLastName: errorMessageRequired,
  fatherPhone: errorMessagePhone,
  isMotherInvolved: errorMessageRequired,
});

export const stepThreeMotherSchema = z.object({
  family: errorMessageRequired,
  isFatherInvolved: errorMessageRequired,
  motherBus: optional,
  motherEmail: errorMessageEmail,
  motherFirstName: errorMessageRequired,
  motherLanguage: errorMessageRequired,
  motherLastName: errorMessageRequired,
  motherNumber: errorMessageRequired,
  motherPhone: errorMessagePhone,
  motherStreet: errorMessageRequired,
  motherZip: errorMessageRequired,
});

export const stepThreeIsSameAddress = z.object({
  isSameAddress: errorMessageRequired,
});

export const stepThreeMotherAndFatherSchema = stepThreeMotherSchema.merge(
  stepThreeBaseFatherSchema.merge(stepThreeIsSameAddress),
);

export const stepThreeOtherSchema = z.object({
  family: errorMessageRequired,
  otherBus: optional,
  otherEmail: errorMessageEmail,
  otherFirstName: errorMessageRequired,
  otherLanguage: errorMessageRequired,
  otherLastName: errorMessageRequired,
  otherNumber: errorMessageRequired,
  otherPhone: errorMessagePhone,
  otherRelation: errorMessageRequired,
  otherStreet: errorMessageRequired,
  otherZip: errorMessageRequired,
});

export const offerFormStepThreeSchema = z.union([
  stepThreeBaseFatherSchema.merge(stepThreeFatherAddressFields),
  stepThreeMotherSchema,
  stepThreeOtherSchema,
  stepThreeMotherAndFatherSchema,
]);

//STEP 4
export const offerFormStepFourDefaultSchema = z.object({
  answer1: errorMessageRequired,
  answer2: errorMessageRequired,
  answer3: errorMessageRequired,
  answer4: errorMessageRequired,
});

export const offerFormStepFourSchoolSchema = z.object({
  answer5: errorMessageRequired,
  answer6: errorMessageRequired,
  answer7: errorMessageRequired,
});

export const offerFormStepFourSchema = offerFormStepFourDefaultSchema.merge(offerFormStepFourSchoolSchema);

export const offerFormSchema = z.union([
  offerFormStepOneSchema,
  offerFormStepTwoSchema,
  offerFormStepThreeSchema,
  offerFormStepFourSchema,
]);

export type TOfferFormStepOneSchema = z.infer<typeof offerFormStepOneSchema>;
export type TOfferFormStepTwoSchema = z.infer<typeof offerFormStepTwoSchema>;
export type TOfferFormStepThreeSchema = z.infer<typeof offerFormStepThreeSchema>;
export type TOfferFormStepFourSchema = z.infer<typeof offerFormStepFourSchema>;
export type TOfferFormSchema = z.infer<typeof offerFormSchema>;
